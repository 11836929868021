'use client'
import { useTranslations } from 'next-intl'
import { useEffect } from 'react'

import { GA_CATEGORY } from 'constant'
import { useIsMobile } from 'store/server-context/device'
import { SendGAEvent } from 'util/GA'

const NotFound = () => {
  const t = useTranslations()
  const isMobile = useIsMobile()

  useEffect(() => {
    SendGAEvent(
      GA_CATEGORY.errorHandling,
      `${isMobile ? 'Mob' : 'Des'}_SomethingWentWrong_Show`
    )
  }, [isMobile])

  return (
    <>
      {/* Next app router still not support metadata */}
      <title>{t('seo.error')}</title>

      <div className='relative min-h-[calc(100%_-_var(--fixed-top))] w-full'>
        <div className='absolute left-0 right-0 top-[calc(50%_+_var(--fixed-top)_/_2)]  z-[1] -translate-y-full transform text-label-l1 min768:top-[96px] min768:translate-y-0'>
          <h2 className='mb-[4px] text-center text-lg font-semibold'>
            {t('error.title')}
          </h2>
          <p className='text-center text-md'>{t('error.desc')}</p>
        </div>
      </div>
    </>
  )
}

export default NotFound
